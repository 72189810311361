import React from "react";
import styled from "styled-components";

//assets
import painelSolar from "assets/images/painel-solar-section2.png";

const SectionTwo = () => {
  return (
    <Container>
      <Content>
        <Image
          src={painelSolar}
          alt="Painel de enrgia solar"
          draggable="false"
        />
        <BoxParagraphs>
          <p>
            Cada vez mais lançaremos mão de fontes renováveis e limpas de
            geração de energia, que não podemos mais chamar de alternativas e
            sim, essenciais. Entre elas Energia Eólica, Gás Natural,
            Biocombustíveis entre outras e, claro, “ENERGIA SOLAR”.
          </p>
          <p>
            O sol está aí, religiosamente, todos os dias, incansavelmente,
            inesgotável já há 4,6 bilhões de anos. Sendo responsável por
            fenômenos meteorológicos, alterações climáticas e pela fotossíntese,
            processo do qual todos os seres vivos são dependentes.
          </p>
          <p>
            Mais do que um imediato retorno financeiro, o nosso objetivo é fazer
            com que todos deem a sua contribuição para consertarmos o estrago já
            feito, e evitar um desastre maior. A recompensa em contribuir para a
            preservação da natureza não há dinheiro que pague.
          </p>
        </BoxParagraphs>
      </Content>
    </Container>
  );
};

export default SectionTwo;

const Container = styled.section`
  background-color: #efefef;
  padding: 70px 0;
`;

const Content = styled.div`
  max-width: ${props => props.theme.queries.xl};
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 0.9fr;
  grid-gap: 0 80px;
  align-items: center;

  @media (max-width: ${props => props.theme.queries.xl}) {
    padding: 0 1rem;
    grid-gap: 0 60px;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 30px 0;
  }
`;

const Image = styled.img`
  max-width: 658px;
  max-height: 648px;
  width: 100%;

  @media (max-width: ${props => props.theme.queries.md}) {
    max-width: 60%;
    margin: 0 auto;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    max-width: 80%;
    margin-bottom: 1.5rem;
  }
`;

const BoxParagraphs = styled.div`
  p:first-child {
    margin-top: 0;
  }

  p {
    font-size: 1.1rem;
    color: ${props => props.theme.color.subtitle};
    text-align: justify;
    line-height: 2;

    margin-top: 40px;
    margin-bottom: 0px;
  }
`;
