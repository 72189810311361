import React from "react";
import styled from "styled-components";

//assets
import image from "assets/images/contact.png";

const SectionHeader = () => {
  return (
    <Container>
      <Content>
        <Title>Vamos conversar?</Title>
      </Content>
    </Container>
  );
};

export default SectionHeader;

const Container = styled.section`
  height: 530px;
  background-color: ${props => props.theme.color.orange};
  padding: 70px 0;

  @media (max-width: ${props => props.theme.queries.md}) {
    padding: 70px 1rem;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    height: 380px;
  }

  @media (max-width: ${props => props.theme.queries.i5}) {
    height: 330px;
  }
`;

const Content = styled.div`
  max-width: ${props => props.theme.queries.xl};
  margin: 0 auto;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  background-image: url(${image});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;

  @media (max-width: ${props => props.theme.queries.md}) {
    background-size: 450px;
    background-position: bottom;
    justify-content: flex-start;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    background-size: contain;
  }
`;

const Title = styled.h1`
  color: ${props => props.theme.color.title};
  margin: 0;
  padding: 0;
  font-size: 4.5rem;
  line-height: 90px;
  letter-spacing: 0.3rem;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  max-width: 650px;

  @media (max-width: ${props => props.theme.queries.md}) {
    max-width: 100%;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    font-size: 2.3rem;
    line-height: 50px;
  }

  @media (max-width: ${props => props.theme.queries.i5}) {
    font-size: 2rem;
    line-height: 50px;
  }
`;
