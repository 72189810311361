import React from "react";
import styled from "styled-components";

//assets
import leaf from "assets/icons/leaf.png";

const SectionOne = () => {
  return (
    <Container>
      <Grid>
        <BoxParagraphs>
          <p>
            A <b>Sirius Solar</b> nasceu com uma proposta de ajudar a tornar o
            mundo um lugar melhor para se viver. Nas ultimas décadas o mundo
            passou por uma transformação radical.
          </p>
          <p>
            Com o fim da era do petróleo abundante, carvão e outras matrizes
            altamente poluentes, que além de muito progresso também muito mal
            fizeram à natureza, chegou a hora de mudarmos a situação e salvarmos
            o planeta da destruição total.
          </p>
        </BoxParagraphs>
        <BoxSlogan>
          <img src={leaf} alt="natureza" draggable={false} />
          <p>
            “Onde a natureza
            <br /> não sobrevive,
            <br /> não há vida”
          </p>
        </BoxSlogan>
      </Grid>
    </Container>
  );
};

export default SectionOne;

const Container = styled.section`
  max-width: 1130px;
  margin: 0 auto;
  padding: 100px 0;

  @media (max-width: ${props => props.theme.queries.md}) {
    padding: 100px 1rem;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    padding: 50px 1rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 290px;
  grid-gap: 0 90px;

  @media (max-width: ${props => props.theme.queries.md}) {
    grid-gap: 0 20px;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    grid-gap: 30px 0;
    grid-template-columns: 1fr;
  }
`;

const BoxParagraphs = styled.div`
  p:first-child {
    text-indent: 40px;
    margin-top: 0;
  }

  p {
    color: ${props => props.theme.color.subtitle};
    font-size: 1.3rem;
    text-align: justify;

    b {
      color: ${props => props.theme.color.title};
    }
  }
`;

const BoxSlogan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100px;
    height: 90px;
  }

  p {
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    margin: 0;
    color: #4c4c4c;
    line-height: 1.5;
    margin-top: -10px;
  }
`;
