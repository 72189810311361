import React from "react";
import styled from "styled-components";
import { Element } from "react-scroll";

//assets
import icon from "assets/icons/icon-solar.png";

interface IModel {
  title_type?: number;
  title_icon?: string;
  image: string;
  title: string;
  paragraph: string;
  textIndent?: string;
  functionalities: Array<string>;
}

const CustomContent = ({
  title_type = 1,
  title_icon,
  image,
  title,
  paragraph,
  functionalities,
  textIndent
}: IModel) => {
  return (
    <Container>
      <Element name={title}>
        {image ? (
          <Image src={image} alt="Aqui tem uma imagem" draggable={false} />
        ) : null}
        {title_type === 1 ? (
          <BoxTitle>
            <img src={icon} alt="Aqui tem uma imagem" draggable={false} />
            <h2>{title}</h2>
          </BoxTitle>
        ) : (
          <BoxTitleTypeTwo>
            <img src={title_icon} alt="Aqui tem uma imagem" draggable={false} />
            <h2>{title}</h2>
          </BoxTitleTypeTwo>
        )}

        {paragraph ? (
          <Paragraph
            dangerouslySetInnerHTML={{ __html: paragraph }}
            textIndent={textIndent}
          />
        ) : null}

        {functionalities ? (
          <List mTop={title_type === 2 ? "0px" : null}>
            {functionalities.map((item, idx) => (
              <ListItem
                weight={item?.includes("#") ? 800 : null}
                mTop={title_type === 2 ? "10px" : null}
                key={idx}
                dangerouslySetInnerHTML={{ __html: item }}
              />
            ))}
          </List>
        ) : null}
      </Element>
    </Container>
  );
};

export default CustomContent;

const Container = styled.div`
  flex: 1;
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  max-height: 630px;
  min-height: 630px;

  @media (max-width: ${props => props.theme.queries.md}) {
    margin-right: 0px;
    height: 50%;
    min-height: 100%;
    margin: 0 auto;
  }
`;

const BoxTitle = styled.div`
  margin-top: 70px;
  display: flex;
  align-items: center;

  img {
    width: 75px;
    height: 70px;
  }

  h2 {
    font-size: 1.75rem;
    margin: 0 0 0 10px;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    margin-top: 45px;

    h2 {
      font-size: 1.2rem;
      margin: 0 0 0 10px;
    }
  }
`;

const BoxTitleTypeTwo = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: flex-end;

  img {
    width: 50px;
    height: 50px;
  }

  h2 {
    text-transform: uppercase;
    font-size: 1rem;
    background-color: ${props => props.theme.color.orange};
    margin: 0 0 2px 10px;
    padding: 0.3rem 0.5rem;
    font-weight: 800;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    h2 {
      text-align: center;
    }
  }
`;

const Paragraph = styled.div`
  margin-top: 20px;
  font-size: 1rem;
  line-height: 2.2;
  text-align: justify;
  text-indent: ${props => props.textIndent || "inherit"};

  p {
    margin: 0;
    color: ${props => props.theme.color.paragraph};
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    font-size: 1rem;
  }
`;

const List = styled.ul`
  margin-top: ${props => props.mTop || "45px"};
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  margin-top: ${props => props.mTop || "30px"};
  display: flex;
  flex-direction: column;

  div {
    font-weight: ${props => props.weight || 700};
    font-size: 1.1rem;
    color: ${props => props.theme.color.orange};
    margin: 0.8rem 0;

    b {
      color: #5d5e5d;
      margin-left: 5px;
      font-weight: 700;
    }
  }

  p {
    font-size: 1rem;
    line-height: 2.2;
    margin: 0;
    padding: 0;
    color: ${props => props.theme.color.paragraph};
  }
`;
