export const theme = {
  color: {
    primary: "#0B0E11",
    secondary: "#12151B",
    background: "#fff",
    gold: "#F0B90B",
    darkGold: "#cea00f",
    lightGold: "#f0b90bab",
    green: "#30C48D",
    lightGreen: "#9CDB70",
    red: "#bf0e08",
    black: "#1B1F22",
    orange: "#FF9E01",
    yellow: "#FBED65",
    white: "#fff",
    gray: "#3E3E3E",
    darkGray: "#232323",
    lightGray: "#9B9B9B",
    grayBackground: "#EFEFEF",
    title: "#232323",
    subtitle: "#666666",
    paragraph: "#3E3E3E"
  },
  boxShadow: "0px 6px 20px rgba(0,0,0,0.08)",
  queries: {
    i5: "320px",
    sm: "440px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    giant: "1920px"
  }
};
