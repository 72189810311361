import React from "react";
import styled from "styled-components";

const SectionSteps = () => {
  return (
    <Container>
      <Grid>
        <FlexColumn>
          <Item>
            <span>
              #<b>1</b>
            </span>
            <p>
              <b>A natureza vem sempre em primeiro lugar</b>, este é o nosso
              primeiro mandamento, sem o respeito à natureza nada vai acontecer,
              a vida não se sustenta se não cuidarmos direito do meio ambiente;
            </p>
          </Item>

          <Item>
            <span>
              #<b>2</b>
            </span>
            <p>
              <b>O atendimento ao nosso cliente</b>, todo cliente para nós é um
              cliente preferencial, não temos distinção de raça, credo, cor,
              poder econômico ou qualquer outra coisa que rotule pessoas,
              empresas ou instituições.
            </p>
          </Item>

          <Item>
            <span>
              #<b>3</b>
            </span>
            <p>
              <b>Responsabilidade</b>, assumimos aqui o compromisso perante
              Deus, nossos familiares, amigos e clientes em prover nossos
              serviços com competência,lisura e transparência, visando máxima
              satisfação e máximo ganho para as pessoas e a sociedade no geral.
            </p>
          </Item>
        </FlexColumn>
        <FlexColumn>
          <Item>
            <span>
              #<b>4</b>
            </span>
            <p>
              <b>Princípios éticos</b>, clareza nas relações com parceiros,
              colaboradores e de todos que fazem parte do nosso círculo de
              negócios, a verdade tem que prevalecer sempre.
            </p>
          </Item>

          <Item>
            <FlexColumn>
              <Fivehash>
                #<b>5</b>
              </Fivehash>
              <Sixhash>
                #<b>6</b>
              </Sixhash>
            </FlexColumn>
            <p>
              <b>Perenidade e Sustentabilidade</b>, sem uma empresa perene
              clientes ficam órfãos de apoio e assistência adequada. Sem
              sustentabilidade, tudo que foi defendido até aqui se torna inócuo
              e sem valor. Como uma empresa perene temos compromisso constante
              com crescimento orgânico, controle de custos, atendimento da
              demanda dos clientes no prazo estabelecido. Como uma empresa
              sustentável fazemos o nosso negócio observando padrões e normas
              nacionais e internacionais vigentes, atuamos para o correto
              armazenamento e descarte de materiais.
            </p>
          </Item>
        </FlexColumn>
      </Grid>
    </Container>
  );
};

export default SectionSteps;

const Container = styled.section`
  max-width: ${props => props.theme.queries.lg};
  margin: 0 auto;
  padding: 180px 0 100px 0;

  @media (max-width: ${props => props.theme.queries.xl}) {
    padding: 180px 1.5rem 100px 1rem;
  }
  @media (max-width: ${props => props.theme.queries.md}) {
    padding: 100px 1.5rem 100px 1rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 95px;

  @media (max-width: ${props => props.theme.queries.md}) {
    grid-gap: 0;
    grid-template-columns: 1fr;
    padding: 0 6rem;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    padding: 0rem;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;

  div:first-child {
    margin-top: 0;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    div:first-child {
      margin-top: 1.6rem;
    }
  }
`;

const Item = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 22px;

  span {
    margin-top: 35px;
    color: ${props => props.theme.color.orange};
    font-size: 3.5rem;
    line-height: normal;
    b {
      font-weight: 900;
    }
  }

  p {
    margin: 0;
    text-align: justify;
    font-size: 1.1rem;
    line-height: 1.8;
    color: ${props => props.theme.color.title};
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    grid-gap: 0px;

    span {
      font-size: 3rem;
    }

    p {
      font-size: 0.9rem;
    }
  }
`;

const Fivehash = styled.span`
  margin-top: 75px !important;
  color: ${props => props.theme.color.orange};
  font-size: 3.5rem;
  line-height: normal;

  @media (max-width: ${props => props.theme.queries.md}) {
    margin-top: 13px !important;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    font-size: 3rem !important;
  }
`;

const Sixhash = styled.span`
  margin-top: 140px !important;
  color: ${props => props.theme.color.orange};
  font-size: 3.5rem;
  line-height: normal;

  @media (max-width: ${props => props.theme.queries.sm}) {
    margin-top: 175px !important;

    span {
      font-size: 3rem;
    }
  }

  @media (max-width: 360px) {
    margin-top: 225px !important;
  }

  @media (max-width: ${props => props.theme.queries.i5}) {
    margin-top: 325px !important;
  }
`;
