import axios from "axios";
import { checkLocalStorage } from "utils/localstorage";
import { whitelistPages } from "./helper";

export const api = (baseUrl: string = "") => {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}${baseUrl}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-SIRIUS": "Site"
    },
    timeout: 20000
  });

  instance.interceptors.request.use(
    function(config) {
      if (whitelistPages()) {
        delete config.headers.Authorization;
        return config;
      }

      const auth = checkLocalStorage("sirius_sola_auth", null);
      if (auth?.token) {
        const Authorization = auth.token ? `Bearer ${auth.token}` : null;
        config.headers.Authorization = Authorization;
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function(response) {
      return response;
    },
    error => {
      if (error.response) {
        if (error.response.status === 401) {
          if (!whitelistPages()) {
            localStorage.removeItem("sirius_sola_auth");
            window.location.href = "/";
          }
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

interface IRequestObject {
  method: string;
  baseUrl: string;
  route: string;
  payload?: Object | Array<Object>;
}

export const request = (req: IRequestObject) => {
  return api()({
    url: `${process.env.REACT_APP_BASE_URL}/${req.baseUrl}/${req.route}`,
    data: req.payload || null,
    method: req.method
  } as any);
};

export const requestPublic = (req: IRequestObject) => {
  return api("/public")({
    url: `${process.env.REACT_APP_BASE_URL}/${req.baseUrl}/${req.route}`,
    data: req.payload || null,
    method: req.method
  } as any);
};
