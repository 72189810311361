import React from "react";
import styled from "styled-components";

//assets
import painelSolar from "assets/images/painel-solar-section3.png";

const SectionThree = () => {
  return (
    <Container>
      <Content>
        <BoxParagraphs>
          <p>
            Então, lembre-se, investindo em energia renovável, você não está
            gastando seu dinheiro com algo supérfluo, que só vai se desvalorizar
            e fazer você perder dinheiro.
          </p>
          <p>
            E, sim, você terá retorno financeiro também, pois o sol não cobra
            nada para brilhar e gerar energia. Depois de um certo tempo, que
            chamamos de ”Payback”, você só terá despesas com as taxas
            obrigatórias dos governos e concessionárias de energia elétrica.
          </p>
          <p>
            Não importa o seu tamanho e os seus propósitos, uma pequena
            residência, um pequeno comércio, uma escola, hospital, uma
            indústria, cabe todo mundo nesta missão que é melhorar a vida neste
            planeta em que habitamos.
          </p>
          <p>
            Portanto, convidamos você para fazer parte desse esforço e ser
            alguém que faz a sua parte.
          </p>
        </BoxParagraphs>
        <Image
          src={painelSolar}
          alt="Painel de enrgia solar"
          draggable="false"
        />
      </Content>
    </Container>
  );
};

export default SectionThree;

const Container = styled.section`
  padding: 70px 0;
`;

const Content = styled.div`
  max-width: ${props => props.theme.queries.xl};
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 50px;
  align-items: center;

  @media (max-width: ${props => props.theme.queries.xl}) {
    padding: 0 1rem;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    grid-gap: 30px 0;
  }
`;

const Image = styled.img`
  max-width: 660px;
  max-height: 560px;
  width: 100%;

  @media (max-width: ${props => props.theme.queries.md}) {
    max-width: 60%;
    margin: 0 auto;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    max-width: 80%;
    margin-bottom: 1.5rem;
  }
`;

const BoxParagraphs = styled.div`
  p:first-child {
    margin-top: 0;
  }

  p {
    font-size: 1.1rem;
    color: ${props => props.theme.color.title};
    text-align: justify;
    line-height: 2;

    margin-top: 30px;
    margin-bottom: 0px;
  }
`;
