import React from "react";
import styled from "styled-components";

//assets
import { ChevronRight } from "@styled-icons/boxicons-regular";

interface IModel {
  setSection: any;
  children: any;
}

const SectionOne = ({ setSection, children }: IModel) => {
  const data = [
    {
      title: "Soluções Solares"
    },
    {
      title: "Energia Renovável"
    },
    {
      title: "Projetos para residências"
    },
    {
      title: "Projetos para empresas"
    },
    {
      title: "Projetos Propriedade Rurais"
    },
    {
      title: "Reparo e Manutenção"
    },
    {
      title: "Otimizações Globais"
    },
    {
      title: "Integradores e Revendedores"
    }
  ];

  return (
    <Container>
      {children}
      <Box>
        <List>
          {data.map((item, idx) => (
            <ListItem key={idx} onClick={() => setSection(item.title)}>
              <span>{item.title}</span>
              <ChevronRight height={40} color="#fff" />
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default SectionOne;

const Container = styled.div`
  display: flex;

  @media (max-width: ${props => props.theme.queries.md}) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const Box = styled.div`
  border: 1px solid #cdcdcd;
  width: 450px;
  height: 700px;
  margin-left: 50px;

  @media (max-width: ${props => props.theme.queries.md}) {
    margin-left: 0px;
    margin-bottom: 50px;
    width: 100%;
    height: 100%;
  }
`;

const List = styled.ul`
  padding: 45px;
  margin: 0;
  list-style: none;

  li:first-child {
    margin-top: 0;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    padding: 25px;
  }
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.color.orange};
  height: 55px;
  padding: 0 0.5rem 0 1.5rem;
  margin-top: 25px;
  cursor: pointer;

  span {
    color: ${props => props.theme.color.white};
    font-size: 1.1rem;
    font-weight: 500;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    span {
      font-size: 1rem;
    }
  }
`;
