import React, { useRef } from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useWindowSize from "hooks/useWindowSize";

//assets
import bndes from "assets/banks/bndes-logo.png";
import santander from "assets/banks/santander-logo.png";
import fundoclima from "assets/banks/fundoclima.jpg";
import sicredi from "assets/banks/sicredi-logo.png";
import bancobrasil from "assets/banks/bancoBrasil.png";
import sicoob from "assets/banks/sicoob-logo.png";
import bvFinanceira from "assets/banks/logo-bv-financeira-2048.png";

import { ChevronLeft, ChevronRight } from "@styled-icons/entypo";

const CustomButtonGroup = props => {
  return (
    <CustomArrows>
      <ChevronLeft
        cursor="pointer"
        width="45px"
        color="#FF9E01"
        onClick={props.previous}
        aria-label="Anterior"
      />
      <ChevronRight
        cursor="pointer"
        width="45px"
        onClick={props.next}
        aria-label="Próximo"
        color="#FF9E01"
      />
    </CustomArrows>
  );
};

const SectionSlider = () => {
  const { width } = useWindowSize();
  const carouselRef = useRef(null);

  const responsive = {
    giant: {
      breakpoint: { max: 4000, min: 1280 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1280, min: 768 },
      items: 3
    },
    table: {
      breakpoint: { max: 768, min: 440 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 440, min: 320 },
      items: 1
    }
  };

  return (
    <Container>
      <Title>FINANCIAMENTOS</Title>
      <Center>
        <CustomCarousel
          ref={carouselRef}
          responsive={responsive}
          arrows={false}
        >
          <img src={bndes} alt="Aqui tem uma imagem" draggable={false} />
          <img src={bvFinanceira} alt="Aqui tem uma imagem" draggable={false} />
          <img src={fundoclima} alt="Aqui tem uma imagem" draggable={false} />
          <img src={santander} alt="Aqui tem uma imagem" draggable={false} />
          <img src={sicoob} alt="Aqui tem uma imagem" draggable={false} />
          <img src={bancobrasil} alt="Aqui tem uma imagem" draggable={false} />
          <img src={sicredi} alt="Aqui tem uma imagem" draggable={false} />
        </CustomCarousel>
        <CustomButtonGroup
          previous={() => carouselRef.current.previous()}
          next={() => carouselRef.current.next()}
        />
      </Center>
    </Container>
  );
};

export default SectionSlider;

const Container = styled.section`
  margin-bottom: 100px;
`;

const Title = styled.h3`
  margin: 0;
  padding: 0;

  text-align: center;
  transform: uppercase;
  color: ${props => props.theme.color.orange};
  letter-spacing: 2px;
  margin-bottom: 5rem;

  @media (max-width: ${props => props.theme.queries.sm}) {
    margin-bottom: 3rem;
  }

  @media (max-width: ${props => props.theme.queries.i5}) {
    margin-bottom: 2rem;
  }
`;

const Center = styled.div`
  position: relative;
  max-width: 1366px;
  display: flex;
  align-items: center;
  margin: 0 auto;

  @media (max-width: ${props => props.theme.queries.xl}) {
    max-width: 1150px;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    max-width: 750px;
  }
`;

const CustomCarousel = styled(Carousel)`
  width: 100%;
  max-width: ${props => props.theme.queries.xl};
  margin: 0 auto;

  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-left: -30px;
      max-height: 80px;
      max-width: 230px;
      filter: grayscale(1);
      transition: all 0.3s;

      :hover {
        filter: grayscale(0);
      }
    }
  }

  @media (max-width: ${props => props.theme.queries.xl}) {
    max-width: ${props => props.theme.queries.lg};
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    max-width: 550px;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    li {
      img {
        margin-left: 0;
      }
    }
  }

  @media (max-width: ${props => props.theme.queries.i5}) {
    li {
      img {
        max-width: 200px;
      }
    }
  }
`;

const CustomArrows = styled.div`
  height: 45px;

  svg:first-child {
    position: absolute;
    left: 0;
  }

  svg:last-child {
    position: absolute;
    right: 0;
  }
`;
