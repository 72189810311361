import React from "react";
import styled from "styled-components";
import { TextInput, Textarea, RadioGroup, Pane, toaster } from "evergreen-ui";
import { useFormik } from "formik";
import InputMask from "react-input-mask";
import axios from "axios";

const SectionForm = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      whatsapp: "",
      phone: "",
      type: "1",
      message: ""
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await axios.post(`${process.env.REACT_APP_API}/contact`, values);
        resetForm();
        formik.setFieldValue("message", "");
        toaster.success("Enviado com sucesso.");
      } catch (error) {
        toaster.danger("Ocorreu um erro ao enviar, tente novamente!");
      }
      setSubmitting(false);
    },
    validate: values => {
      let errors = {} as any;

      if (!values.name) {
        errors.name = `o campo "nome" é obrigatório.`;
      }
      if (values.name.length < 5) {
        errors.name = `o campo "nome" deve conter no mínimo 5 caracteres.`;
      }
      if (!values.email) {
        errors.email = `o campo "e-mail" é obrigatório.`;
      }
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = `insira um e-mail válido.`;
      }
      if (!values.type) {
        errors.type = `selecione um tipo de contato.`;
      }
      if (!values.message) {
        errors.message = `o campo "como podemos ajudar?" é obrigatório.`;
      }

      return errors;
    }
  });

  return (
    <Container>
      <Title>Preencha o formulário e entraremos em contato:</Title>
      <Form onSubmit={formik.handleSubmit}>
        <RowInputs>
          <div>
            <TextInput
              height="45px"
              placeholder="Nome"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            <MessageError>
              {formik.errors.name && formik.touched.name && formik.errors.name}
            </MessageError>
          </div>
          <div>
            <TextInput
              height="45px"
              placeholder="E-mail"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <MessageError>
              {formik.errors.email &&
                formik.touched.email &&
                formik.errors.email}
            </MessageError>
          </div>
        </RowInputs>

        <RowInputs marginTop="30px">
          <CustomInputMask
            name="whatsapp"
            mask="(99) 99999-9999"
            value={formik.values.whatsapp}
            onChange={formik.handleChange}
            placeholder="Whatsapp"
          />
          <CustomInputMask
            name="phone"
            mask="(99) 9999-9999"
            value={formik.values.phone}
            onChange={formik.handleChange}
            placeholder="Telefone"
          />
        </RowInputs>

        <div>
          <CustomRadioGroup
            name="type"
            display="flex"
            marginTop="15px"
            value={formik.values.type}
            size={16}
            options={[
              { label: "Residencial", value: "1" },
              { label: "Comercial", value: "2" }
            ]}
            onChange={event => formik.setFieldValue("type", event.target.value)}
          />
          <MessageError>
            {formik.errors.type && formik.touched.type && formik.errors.type}
          </MessageError>
        </div>

        <div>
          <CustomTextarea
            paddingTop="1.3rem"
            marginTop="15px"
            name="message"
            placeholder="Como podemos te ajudar?"
            height="220px"
            value={formik.values.message}
            onChange={formik.handleChange}
          />
          <MessageError>
            {formik.errors.message &&
              formik.touched.message &&
              formik.errors.message}
          </MessageError>
        </div>

        <SendButton type="submit">
          {formik.isSubmitting ? "Enviando..." : `Enviar`}
        </SendButton>
      </Form>
    </Container>
  );
};

export default SectionForm;

const Container = styled.section`
  padding: 60px 0 120px 0;
  max-width: ${props => props.theme.queries.xl};
  margin: 0 auto;

  @media (max-width: ${props => props.theme.queries.xl}) {
    padding: 60px 1rem 120px 1rem;
  }
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 2.3rem;
  text-align: center;
  color: ${props => props.theme.color.subtitle};
  margin: 0;

  @media (max-width: ${props => props.theme.queries.md}) {
    font-size: 1.8rem;
  }

  @media (max-width: ${props => props.theme.queries.i5}) {
    font-size: 1.5rem;
  }
`;

const Form = styled.form`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
`;

const RowInputs = styled(Pane)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 35px;

  input {
    width: -webkit-fill-available;
    text-indent: 10px;
    border: 1px solid #d3d3d3;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    grid-gap: 30px;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    grid-gap: 30px;
    grid-template-columns: 1fr;
  }
`;

const CustomRadioGroup = styled(RadioGroup)`
  label {
    margin-right: 1rem;
  }
`;

const CustomInputMask = styled(InputMask)`
  height: 35px;
  border: 2px solid #d3d3d3 !important;
  border-radius: 0.3rem;
  box-shadow: none !important;

  :focus {
    outline: none;
    box-shadow: inset 0 0 2px rgb(67 90 111 / 14%), inset 0 0 0 1px #579ad9,
      0 0 0 3px rgb(16 112 202 / 14%);
  }

  ::placeholder {
    color: #b8bfc8;
  }
`;

const CustomTextarea = styled(Textarea)`
  border: 1px solid #d3d3d3;
`;

const SendButton = styled.button`
  background-color: ${props => props.theme.color.orange};
  color: ${props => props.theme.color.white};
  border-radius: 2rem;
  max-width: 383px;
  width: 100%;
  height: 40px;
  outline: none;
  margin-top: 30px;
  border: none;
  text-align: center;
  font-size: 1.2rem;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
  align-self: center;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    width: 100%;
  }
`;

const MessageError = styled.div`
  transition: 0.2s ease-in-out;
  color: ${props => props.theme.color.red};
  font-size: 0.9rem;
  margin-bottom: 5px;
`;
