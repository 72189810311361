import example, { ExampleModel } from "./example";

export type StoreModel = {
  example: ExampleModel;
};

const model: StoreModel = {
  example
};

export default model;
