import React, { Fragment, useState, useEffect } from "react";
import { MenuIcon } from "evergreen-ui";
import styled from "styled-components";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

export const Layout = (props) => {
  const [displaySidebar, setDisplay] = useState(false as boolean);

  useEffect(() => {
    setDisplay(false);
  }, [window.location.pathname]);

  return (
    <Fragment>
      <Grid>
        <GridHeader>
          <Navbar
            displaySidebar={() => setDisplay(true)}
            icon={MenuIcon}
          />
        </GridHeader>
        <Sidebar
          show={displaySidebar}
          setDisplay={() => setDisplay(false)}
          closeSidebar={() => {
            setDisplay(false);
          }}
        />
        <GridContent>{props.children}</GridContent>
        <Footer />
      </Grid>
    </Fragment>
  );
};

const Grid = styled.div`
  background: ${props => props.theme.color.background};
  @media (max-width: 1200px) {
    display: block;
  }
`;

const GridHeader = styled.div`
  height: 100%;
`;

const GridContent = styled.div`
  background: ${props => props.theme.color.background};
  grid-area: content;
`;
