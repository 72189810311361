import React from "react";
import styled from "styled-components";

//components
import SectionInfo from "components/Home/SectionInfo";
import SectionWhyChoose from "components/Home/SectionWhyChoose";
import SectionHowItWorks from "components/Home/SectionHowItWorks";

const Home = () => {

  return (
    <Container>
      <SectionInfo/>
      <SectionWhyChoose/>
      <SectionHowItWorks/>
    </Container>
  );
};

export default Home;

const Container = styled.div``
