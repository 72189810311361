import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Pane, toaster } from "evergreen-ui";
import axios from "axios";

//icons
import { Location } from "@styled-icons/evil";
import { Mail } from "@styled-icons/feather";
import { Facebook } from "@styled-icons/bootstrap";
import {
  InstagramWithCircle,
  LinkedinWithCircle
} from "@styled-icons/entypo-social";
import { Whatsapp } from "@styled-icons/remix-fill";
import logoWhite from "assets/logo/logo_sirius_white.png";
import absolar from "assets/images/absolar.png";

//icons sustainable
import seven from "assets/sustainable/7.png";
import eight from "assets/sustainable/8.png";
import eleven from "assets/sustainable/11.png";
import twelve from "assets/sustainable/12.png";
import thirteen from "assets/sustainable/13.png";
import fourteen from "assets/sustainable/14.png";
import fifteen from "assets/sustainable/15.png";

const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <Container>
      <Content>
        <Pane>
          <InlineColumnContent>
            <ColumnContent>
              <Logotype src={logoWhite} alt="Sirius Solar" />
              <InfoSirius marginTop="2rem">
                <LocationIcon width="50px" />
                <InfoSiriusText>
                  Rua Nossa Senhora Aparecida, 89, Paraisópolis - MG
                </InfoSiriusText>
              </InfoSirius>
              <InfoSirius marginTop=".2rem">
                <EmailIcon width="30px" height="28px" />
                <InfoSiriusText>siriussolarbr@gmail.com</InfoSiriusText>
              </InfoSirius>
            </ColumnContent>

            <ColumnContent marginTop="2.5rem">
              <TitleLinks>
                <div />
                <span>links úteis</span>
              </TitleLinks>
              <ContentLinks>
                <Link to="/">Início</Link>
                <Link to="/sobre-nos">Sobre nós</Link>
                <Link to="/servicos">Serviços</Link>
                <Link to="/contato">Contato</Link>
                <a
                  href="https://loja.siriussolar.com.br/pagina/politica-de-reembolso"
                  target="_blank"
                  rel="noopener"
                >
                  Política de Reembolso
                </a>
              </ContentLinks>
            </ColumnContent>
          </InlineColumnContent>

          <BoxSustainable>
            <span>
              Indicadores Brasileiros para os Objetivos de Desenvolvimento
              Sustentável:
            </span>
            <div>
              <img src={seven} alt="Aqui tem uma imagem" />
              <img src={eight} alt="Aqui tem uma imagem" />
              <img src={eleven} alt="Aqui tem uma imagem" />
              <img src={twelve} alt="Aqui tem uma imagem" />
              <img src={thirteen} alt="Aqui tem uma imagem" />
              <img src={fourteen} alt="Aqui tem uma imagem" />
              <img src={fifteen} alt="Aqui tem uma imagem" />
            </div>
          </BoxSustainable>
        </Pane>
        <ColumnContent marginTop="2.5rem">
          <TitleLinks>
            <div />
            <span>NEWSLETTER</span>
          </TitleLinks>
          <ContentLinks>
            <div>Receba nossas novidades!</div>
          </ContentLinks>
          <ContentLinks>
            <Input
              value={name}
              onChange={e => setName(e.target.value)}
              mTop="5px"
              placeholder="Nome"
            />
            <Input
              value={email}
              onChange={e => setEmail(e.target.value)}
              mTop="15px"
              placeholder="E-mail"
            />
            <SendButton
              onClick={async () => {
                if (name.length < 5) {
                  toaster.warning(`Nome deve conter no mínimo 5 caracteres.`);
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
                ) {
                  toaster.warning(`Insira um e-mail válido.`);
                } else {
                  setLoading(true);
                  try {
                    await axios.post(
                      `${process.env.REACT_APP_API}/newsletter`,
                      {
                        name,
                        email
                      }
                    );
                    toaster.success("Enviado com sucesso.");
                    setEmail("");
                    setName("");
                    setLoading(false);
                  } catch (error) {
                    toaster.danger(
                      "Ocorreu um erro ao enviar, tente novamente!"
                    );
                    console.error(error);
                    setLoading(false);
                  }
                }
              }}
            >
              {loading ? "Enviando..." : "Enviar"}
            </SendButton>
          </ContentLinks>
        </ColumnContent>

        <Pane display="flex" flexDirection="column">
          <ColumnContent>
            <BoxSocialMedia>
              <a
                href="https://www.facebook.com/SiriusSolarBR"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook width="35px" fill="#FF9E01" cursor="pointer" />
              </a>
              <a
                href="https://www.instagram.com/siriussolarbr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramWithCircle
                  width="35px"
                  height="35px"
                  fill="#FF9E01"
                  cursor="pointer"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/sirius-solar-br/?viewAsMember=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinWithCircle
                  width="35px"
                  fill="#FF9E01"
                  cursor="pointer"
                />
              </a>
              <a
                href="https://wa.me/5512982330940"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Whatsapp width="37px" fill="#FF9E01" cursor="pointer" />
              </a>
            </BoxSocialMedia>
          </ColumnContent>
          <MiniLogoSitius src={logoWhite} alt="Aqui tem uma imagem" />
        </Pane>
      </Content>
    </Container>
  );
};

export default Footer;

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => props.theme.color.darkGray};
  width: 100%;
`;

const Content = styled(Pane)`
  max-width: ${props => props.theme.queries.xl};
  padding: 55px 0;
  width: 100%;
  margin: 0 auto;

  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 2.5fr 1fr 1fr;
  @media (max-width: ${props => props.theme.queries.xl}) {
    padding: 55px 1rem;
  }
  @media (max-width: ${props => props.theme.queries.md}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Logotype = styled.img`
  width: 164px;
  height: 67px;
  margin-left: -10px;
`;

const ColumnContent = styled(Pane)`
  display: flex;
  flex-direction: column;
`;

const InlineColumnContent = styled.div`
  display: flex;
  @media (max-width: ${props => props.theme.queries.md}) {
    flex-direction: column;
  }
`;

//INFO SIRIUS

const InfoSirius = styled(Pane)`
  display: flex;
  align-items: flex-start;
`;

const InfoSiriusText = styled.div`
  margin-left: 15px;
  color: ${props => props.theme.color.white};
  font-weight: 600;
  font-size: 1rem;
`;
//

const TitleLinks = styled.div`
  display: flex;
  align-items: center;

  div {
    width: 2rem;
    height: 2px;
    background-color: ${props => props.theme.color.yellow};
  }

  span {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${props => props.theme.color.orange};
    margin-left: 12px;
    text-transform: uppercase;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    div {
      display: none;
    }

    span {
      margin-left: 0;
    }
  }
`;

const ContentLinks = styled.div`
  margin-top: 1rem;
  margin-left: 45px;
  display: flex;
  flex-direction: column;

  a,
  div {
    color: ${props => props.theme.color.white};
    font-size: 1.1rem;
    font-weight: 600;
    text-decoration: none;
    margin-top: 0.2rem;
  }

  a {
    width: 210px;
  }

  a:hover {
    color: ${props => props.theme.color.orange};
    transition: all 0.3s;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    margin-left: 0;
  }
`;

const Input = styled.input`
  height: 45px;
  width: 285px;
  border: 2px solid #666;
  border-radius: 2rem;
  background-color: transparent;
  text-indent: 20px;
  color: #666;
  font-size: 1.2rem;
  margin-top: ${props => props.mTop};
  outline: none;
  padding: 0;
  @media (max-width: ${props => props.theme.queries.md}) {
    width: 100%;
  }
`;

const SendButton = styled.button`
  background-color: ${props => props.theme.color.orange};
  color: ${props => props.theme.color.white};
  border-radius: 2rem;
  width: 285px;
  height: 45px;
  outline: none;
  margin-top: 20px;
  border: none;
  text-align: left;
  text-indent: 20px;
  font-size: 1.2rem;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    width: 100%;
  }
`;

//Social media

const BoxSocialMedia = styled.div`
  margin-top: 2.5rem;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 35px);
  grid-template-rows: 35px;
  grid-gap: 15px;

  /* @media (max-width: ${props => props.theme.queries.md}) {
    grid-template-columns: repeat(4, 35px);
  } */
`;

//icons

const LocationIcon = styled(Location)`
  fill: #fbed65 !important;
  margin-left: -10px;

  @media (max-width: ${props => props.theme.queries.md}) {
    margin-top: -10px;
  }
  @media (max-width: ${props => props.theme.queries.sm}) {
    margin-left: -5px;
    margin-top: 0;
  }
`;

const EmailIcon = styled(Mail)`
  stroke: #fbed65 !important;
`;

const Absolar = styled.img`
  width: 150px;
  height: 140px;
  margin: 2rem auto 0 auto;
  border-radius: 0.5rem;
`;

const MiniLogoSitius = styled.img`
  width: 110px;
  height: 48px;
  margin: 0.5rem auto 0 auto;
`;

//Coluna sustentavel

const BoxSustainable = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

  span {
    font-size: 0.9rem;
    color: ${props => props.theme.color.white};
    font-weight: 600;
  }

  div {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    img {
      width: 66px;
      height: 66px;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    img:last-child {
      margin-right: 0;
    }
  }
`;
