import React from "react";
import ReactDOM from "react-dom";
import { StoreProvider } from "easy-peasy";
import { ThemeProvider } from "styled-components";
import store from "./configureStore";
import { GlobalStyle } from "./configureStyle";
import { theme } from "./theme";
import Routes from "./routes/index";
import history from "./utils/history";
import * as serviceWorker from "./serviceWorker";
import "animate.css/animate.css";

ReactDOM.render(
  <>
    <ThemeProvider theme={theme}>
      <StoreProvider store={store}>
        <GlobalStyle />
        <Routes history={history} />
      </StoreProvider>
    </ThemeProvider>
  </>,
  document.getElementById("root")
);

if (process.env.NODE_ENV !== "production") {
  //@ts-ignore
  if (module.hot) {
    //@ts-ignore
    module.hot.accept();
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
