import React from "react";
import styled from "styled-components";
import { Pane } from "evergreen-ui";
import ScrollAnimation from "react-animate-on-scroll";

//assets
import banner from "assets/images/imagem-home.png";

interface IModel {
  title: string;
}

const Banner = ({ title }: IModel) => {
  return (
    <Container>
      <BannerImage />
      <Shadow />
      <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
        <Title>{title}</Title>
      </ScrollAnimation>
    </Container>
  );
};

export default Banner;

const Container = styled(Pane)`
  height: 100vh;
  width: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  .animated {
    z-index: 1;
  }
`;

const BannerImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: #eee;
  background-image: url(${banner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const Shadow = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

const Title = styled.h1`
  z-index: 1;
  color: ${props => props.theme.color.white};
  font-weight: 900;
  font-size: 4.5rem;
  text-transform: uppercase;
  text-align: center;
  line-height: 100px;
  max-width: 600px;

  @media (max-width: ${props => props.theme.queries.sm}) {
    padding: 0 1rem;
    font-size: 3rem;
    line-height: 70px;
  }

  @media (max-width: ${props => props.theme.queries.i5}) {
    font-size: 2.5rem;
    line-height: 50px;
  }
`;
