import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { scroller } from "react-scroll";
import queryString from "query-string";

//components
import SectionOne from "components/Services/SectionOne";
import CustomContent from "components/Services/CustomContent";
import Steps from "components/Services/Steps";

//assets
import image1 from "assets/images/services-1.png";
import image2 from "assets/images/services-2.png";
import image3 from "assets/images/reparo_manutencao.png";
import image4 from "assets/images/services-3.png";
import house from "assets/icons/house.png";
import empresas from "assets/icons/empresas.png";
import rural from "assets/icons/rural.png";
import parceiros from "assets/icons/parceiros.png";

const Services = () => {
  const { replace } = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const querySection = query.section || "";
  const [section, setSection] = useState("");

  useEffect(() => {
    if (querySection.includes("energy")) {
      setSection("Energia Renovável");
    }
  }, [querySection]);

  useEffect(() => {
    if (!!section) {
      scroller.scrollTo(section, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart"
      });
    }
    replace("/servicos");
    return () => setSection("");
  }, [section]);

  return (
    <div>
      <Container>
        <SectionOne setSection={setSection}>
          <CustomContent
            image={image1}
            title={"Soluções Solares"}
            textIndent="3.5rem"
            paragraph={
              "<p>Nos esforçamos de maneira contínua e inovadora para trazer a você produtos e soluções que possam transformar com eficiência a energia solar em energia confiável para gerar grande economia com gastos com energia, agregando sustentabilidade ao utilizar a energia natural.</p><p>A energia fotovoltaica oferece de maneira eficiente, ecologicamente correta e com custos reduzidos e custos de manutenção mínimos a energia necessária para a todos as necessidades, aproveitando a energia natural do sol para uma produção de energia de alta qualidade.</p>"
            }
            functionalities={null}
          />
        </SectionOne>

        <Wrap>
          <CustomContent
            image={image2}
            title={"Energia Renovável"}
            paragraph={
              "<p>Porque a energia renovável é importante.</p><p>A energia renovável veio para ficar. Ela está se tornando uma parte muito maior da produção total de energia e oferece uma rede de energia mais diversificada para dar a indivíduos e empresas o poder de controle sobre como sua energia é produzida. Isso não é tudo, a importância em produzir energia limpa e renovável não para por aí. Como podemos ver a seguir as energias renováveis apoiam, infraestrutura, economia e meio ambiente.</p>"
            }
            functionalities={[
              "<div>1 - Energia renovável reduz o impacto ambiental:</div><p>A energia renovável reduz o impacto devastador dos combustíveis fósseis nos ecossistemas.</p><p>A geração de eletricidade a partir de combustíveis fósseis é responsável por uma grave poluição do ar, causando impactos devastadores nos ecossistemas, incluindo poluição das águas, chuva ácida, poluição e liberação de gases de efeito estufa, contribuindo ainda mais para a mudança climática global.</p><p>A energia de fonte renovável é uma fonte infinita de energia. As regulamentações ambientais são um fator de apoio ao uso da energia limpa.</p><p>O custo de produção está caindo para níveis cada vez mais aceitáveis, de 2010 a 2017 o custo de instalação de energia solar caiu 70%, quanto mais pessoas e empresas aderem ao sistema mais os preços se tornam competitivos.</p>",
              //
              "<div>2 - Energia renovável beneficia a economia:</div><p>A energia renovável pode trazer estabilidade aos preços da energia, aumentando o número de fontes de energia para atender à demanda.</p><p>As energias renováveis estão trazendo estabilidade aos preços da energia. A flutuação dos preços de outras energias não é sentida nas energias renováveis. Além disso, as fontes de energia renováveis, como eólica e solar não têm os mesmos custos ou requisitos tarifários associados a elas. Isso permite que as empresas de serviços de energia repassem a economia para o cliente. Agora, o custo de produção é o fator determinante para o preço, e os consumidores estão vencendo.</p>",
              //
              "<div>3 - Energia renovável oferece mais segurança:</div><p>Múltiplas fontes de energia também podem fornecer confiabilidade porque, se a produção cair nas outras fontes, fontes de energia, como solar, eólica podem compensar.</p><p>Assim como várias fontes de energia podem reduzir os custos, também podem fornecer mais confiabilidade.</p><p>Uma variedade de fontes de energia renovável reduz riscos enquanto preserva os recursos naturais.</p>",
              //
              "<div>4 - Energia renovável apoia o crescimento do emprego:</div><p>Acredite ou não a energia renovável exige muita mão-de-obra e pode proporcionar um crescimento significativo do emprego.</p><p>Com a tecnologia como força motriz desse mercado, o crescimento do emprego está se estendendo além da força de trabalho.</p><p>A sustentabilidade é outra característica importante para esta indústria em crescimento. Atualmente, é visto como um dos mercados de trabalho de crescimento mais rápido e não parece que vai desacelerar tão cedo.</p>",
              //
              "<div>5 - A energia renovável pode melhorar a saúde pública:</div><p>A poluição do ar e da água produzida pela produção de carvão, petroleo e gás natural não é emitida por tecnologias de energias limpas.</p><p>A produção de carvão e gás natural está associada a graves riscos à saúde. Energia Eólica e solar também não requerem água para funcionar, o que reduz as chances de poluir as águas subterrâneas ou outras fontes de água.</p>"
            ]}
          />
        </Wrap>

        <Wrap mTop="50px">
          <CustomContent
            title_type={2}
            title_icon={house}
            image={null}
            title={"Projetos para residências"}
            paragraph={""}
            functionalities={[
              "<div>Economize até 95% da sua conta de energia</div><p>A Sirius Solar tem a melhor solução para você ganhar autonomia na sua geração de energia elétrica. Com nossos kits de energia solar para residências, você tem um investimento seguro e extremamente rentável. Sem contar que você estará ajudando nosso planeta, gerando energia limpa e inesgotável. Trabalhamos com um amplo leque de fornecedores mundiais, o que nos garante alta qualidade e rápida entrega!</p>"
            ]}
          />
        </Wrap>

        <Wrap mTop="0px">
          <CustomContent
            title_type={2}
            title_icon={empresas}
            image={null}
            title={"Projetos para empresas"}
            paragraph={""}
            functionalities={[
              "<div>Economize na conta de energia da sua empresa.</div><p>Com os produtos de energia solar fotovoltaica da Sirius, empresas conseguem adquirir autonomia na geração de energia elétrica. É possível ter economia de até 95% da conta de energia. Dependendo do consumo, haverá apenas a taxa básica da concessionária de energia elétrica. O sistema de energia solar fotovoltaica tem garantia de 25 anos e, praticamente, não tem necessidade de manutenção.</p><p>Faça uma simulação agora mesmo e descubra qual o melhor tipo de kit de energia solar para sua empresa.</p>",
              //
              "<div>Vantagens para o seu negócio.</div><p>Algumas vantagens proporcionadas pela geração de energia solar fotovoltaica para sua empresa:</p>",
              //
              "<div>#<b>Economia imediata:</b></div><p>Queda no consumo, chegando a pagar apenas a taxa básica da concessionária fornecedora de energia elétrica. Redução de até 95% da conta.</p>",
              //
              "<div>#<b>Baixa manutenção:</b></div><p>O custo de manutenção é praticamente inexistente e a garantia do sistema é de, em média, 25 anos.</p>",
              //
              "<div>#<b>Diferencial competitivo:</b></div><p>Sua empresa se destaca da concorrência ganhando visibilidade ao se tornar sustentável e amiga do planeta.</p>",
              //
              "<div>#<b>Utilize seus créditos:</b></div><p>Empresas com filiais sob o mesmo CNPJ podem utilizar os créditos da geração de energia em outras unidades.</p>",
              //
              "<div>#<b>Segurança contra aumento de taxas:</b></div><p>Sua empresa não sofre com os aumentos nas tarifas de energia elétrica.</p>"
            ]}
          />
        </Wrap>

        <Wrap mTop="0px">
          <CustomContent
            title_type={2}
            title_icon={rural}
            image={null}
            title={"Projetos Propriedade Rurais"}
            paragraph={""}
            functionalities={[
              "<div>Sistemas customizados para sua fazenda, sítio ou chácara.</div><p>Utilize os programas de financiamento para zona rural e financie o sistema de energia solar fotovoltaica com taxas de juros baixíssimas.</p><p>É possível transformar sua propriedade rural em uma grande geradora de energia solar, que pode ser usada para qualquer finalidade: desde acender lâmpadas até ligar a cerca elétrica.</p><p>A economia com custos de energia pode ser de até 95%. Sem contar que você não sofre com aumentos de taxas na energia elétrica.</p><p>A energia solar fotovoltaica é uma fonte sustentável e que traz inúmeras vantagens para propriedades rurais. Com os kits de energia solar para zona rural, você adquire uma fonte inesgotável de energia elétrica.Você tem garantia de 25 anos, não sofre com aumento de tarifas na conta de energia elétrica e ainda pode utilizar de benefícios concedidos para financiamento.</p><p>Faça uma simulação e receba o contato de um dos nossos especialistas.</p>"
            ]}
          />
        </Wrap>

        <Wrap>
          <CustomContent
            image={image3}
            title={"Reparo e Manutenção"}
            paragraph={""}
            functionalities={[
              "<div>Contratos de manutenção solar.</div><p>A SIRIUS Solar oferece contratos de manutenção para seus Sistemas Solares, dentro e fora da rede (on grid e off grid)!</p><p>Um contrato de manutenção de Sistema Solar é uma ótima maneira de revisar seu sistema, uma vez por ano para evitar reparos dispendiosos. Todos os nossos especialistas em reparos solares são eletricistas licenciados, treinados para limpar e verificar adequadamente todos os componentes do seu sistema de energia solar. Se surgir um problema, seu especialista em reparos solares fará uma estimativa de peças e mão de obra para sua aprovação.</p>",
              //
              "<div>Cobertura com a qual você pode contar.</div><p>A Sirius Solar oferece contratos de manutenção de painéis solares e seus sistemas elétricos, (dentro e fora da rede)!</p><p>Os contratos de manutenção dos sistemas solares estão disponíveis em incrementos de 2, 3 e 5 anos. Os preços variam dependendo do tamanho do Sistema Solar, onde está localizado e do tipo de instalação. Entre em contato conosco para obter o seu Contrato de Manutenção do seu Sistema Solar para que seu sistema esteja sempre funcionando perfeitamente!</p><p>Utilize os nossos canais de comunicação indicados neste site.</p>",
              //
              "<div>Reparando sistemas solares fotovoltaicos.</div><p>Você instalou seu sistema por outra empresa? Não tem problema, a Sirius Solar pode consertar erros comuns cometidos por instalações inadequadas.</p><p>Acidentes e imprevistos de ordem da natureza ou mesmo por falhas humana podem colocar o seu sistema em funcionamento inadequado ou até mesmo parar de funcionar. Estamos aqui para garantir que você tenha o melhor sistema de energia solar em perfeito funcionamento! A Sirius Solar pode consertar seus sistemas de energia solar mal projetados ou sistemas quebrados. Escolha os especialistas em energia. Escolha a Sirius solar.</p>",
              //
              "<div>Pensando em instalar seu próprio sistema fotovoltaico solar?</div><p>A Sirius Solar recomenda que você contrate um eletricista licenciado para instalar seu sistema elétrico solar. Existem muitas etapas no processo desde o projeto até a interconexão à rede elétrica, que exigem uma empresa legalmente estabelecida e licenciada.</p>"
            ]}
          />
        </Wrap>

        <Wrap>
          <CustomContent
            image={image4}
            title={"Otimizações Globais"}
            paragraph={""}
            functionalities={[
              "<p>Otimizar os sistemas solares fotovoltaicos:</p><div>Planejamento, seleção de componentes e monitoramento.</div><p>Todos os proprietários de um sistema fotovoltaico, seja numa casa privada ou para um sistema fotovoltaico comercial, querem obter o maior rendimento possível do sistema. Antes da instalação de um sistema solar, há alguns pontos que devem ser considerados de perto, de forma a planejar o sistema da maneira mais eficiente possível. Mas, além disso, existem ainda, naturalmente, possibilidades de otimizar um sistema fotovoltaico já existente. Otimizar o seu sistema solar significa escolher produtos adequados de fabricantes e fornecedores confiáveis e certificados pela ANEEL. Para poder fornecer as melhores soluções possíveis aos clientes.</p>",
              //
              "<div>A otimização do sistema fotovoltaico começa com o planeamento.</div><p>Muitos erros, que mais tarde conduzem a uma redução no rendimento, ocorrem logo durante o planeamento do sistema solar. Portanto, é importante considerar todos os aspetos críticos já na fase de planeamento. Além dos fatores conhecidos, como o alinhamento dos módulos, a minimização do sombreamento, um ângulo solar de 90°, existem outros fatores que desempenham um papel, especialmente ao planear instalações fotovoltaicas maiores.</p>"
            ]}
          />
        </Wrap>

        <Wrap mTop="0px">
          <CustomContent
            title_type={2}
            title_icon={parceiros}
            image={null}
            title={"Integradores e Revendedores"}
            paragraph={""}
            functionalities={[
              "<div>Venha ser um parceiro da Sirius Solar.</div><p>Temos condições especiais para nossos parceiros. A Sirius está sempre disposta a colaborar e a viabilizar os projetos de sua rede de integradores.</p><p>Nós somos uma empresa de engenharia. Por isso, contamos com nossa rede de integradores para realizar a instalação dos nossos kits solares para residências, empresas, indústrias e propriedades rurais.</p>"
            ]}
          />
        </Wrap>
      </Container>
      <Steps />
    </div>
  );
};

export default Services;

const Container = styled.div`
  padding: 90px 0 20px 0;
  max-width: ${props => props.theme.queries.xl};
  margin: 0 auto;

  @media (max-width: ${props => props.theme.queries.xl}) {
    padding: 60px 1rem 20px 1rem;
  }
`;

const Wrap = styled.div`
  margin-top: ${props => props.mTop || "80px"};
  max-width: 780px;
`;
