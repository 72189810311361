import React, { useRef } from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//icons
import next from "assets/icons/next.png";

const Steps = () => {
  const carouselRef = useRef(null);

  const data = [
    {
      number: "01",
      hasIcon: true,
      title: "Simulação do<br/> Sistema Fotovoltaico</b>",
      description:
        "Escolha o kit que<br/> melhor atende as<br/> suas necessidades"
    },
    {
      number: "02",
      hasIcon: true,
      title: "Proposta<br/> personalizada",
      description: "Após a simulação feita em nosso site entramos em contato"
    },
    {
      number: "03",
      hasIcon: true,
      title: "Instalação do<br/> Sistema Solar",
      description: "Executamos o projeto e instalação do sistema"
    },
    {
      number: "04",
      hasIcon: true,
      title: "Funcionamento<br/> do sistema",
      description:
        "Com o sistema instalado você já começa a receber os créditos de energia"
    },
    {
      number: "05",
      hasIcon: false,
      title: "Garantia e<br/> Pós Venda",
      description:
        "Garantia de 25 anos de eficiência. Opcional acompanhamento online do seu sistema solar"
    }
  ];

  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 440 },
      items: 3,
      partialVisibilityGutter: 10
    },
    mobile: {
      breakpoint: { max: 440, min: 320 },
      items: 1,
      partialVisibilityGutter: 10
    }
  };

  return (
    <Container>
      <Title>Confira o passo a passo de um projeto solar!</Title>
      <Grid>
        {data?.map((item, idx) => (
          <Card key={idx}>
            <CardHeader>
              <span>{item.number}</span>
              {item.hasIcon ? (
                <img src={next} alt="aqui tem uma imagem" draggable={false} />
              ) : null}
            </CardHeader>
            <b dangerouslySetInnerHTML={{ __html: item.title }} />
            <p dangerouslySetInnerHTML={{ __html: item.description }} />
          </Card>
        ))}
      </Grid>
      {/* Carousel */}
      <BoxCarousel>
        <Carousel
          ref={carouselRef}
          responsive={responsive}
          showDots={false}
          arrows={false}
          containerClass="carousel-container"
        >
          {data?.map((item, idx) => (
            <Card key={idx}>
              <CardHeader>
                <span>{item.number}</span>
                {item.hasIcon ? (
                  <img
                    src={next}
                    alt="aqui tem uma imagem"
                    draggable={false}
                    onClick={() => carouselRef.current.next()}
                  />
                ) : null}
              </CardHeader>
              <b dangerouslySetInnerHTML={{ __html: item.title }} />
              <p dangerouslySetInnerHTML={{ __html: item.description }} />
            </Card>
          ))}
        </Carousel>
      </BoxCarousel>
    </Container>
  );
};

export default Steps;

const Container = styled.div`
  margin-top: 1.2rem;
  display: flex;
  flex-direction: column;
  max-width: ${props => props.theme.queries.xl};
  margin: 0 auto 60px auto;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 1.1rem;
  color: ${props => props.theme.color.orange};

  @media (max-width: ${props => props.theme.queries.xl}) {
    padding: 0 1rem;
  }
`;

const Grid = styled.div`
  margin-top: 1.2rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;

  @media (max-width: ${props => props.theme.queries.md}) {
    display: none;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  background-color: #f3f2f3;
  min-height: 170px;

  b {
    font-size: 1rem;
    line-height: 19px;
  }

  p {
    margin: 0.7rem 0 0 0;
    font-size: 0.8rem;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    width: 80%;
    height: 100%;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    width: 90%;
    height: 100%;
    min-height: 100%;

    b > br {
      display: none;
    }

    p > br {
      display: none;
    }
  }
`;

const CardHeader = styled.div`
  margin-bottom: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: 800;
    color: ${props => props.theme.color.orange};
    font-size: 1.7rem;
  }

  img {
    margin-right: 0.2rem;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    img {
      width: 35px;
    }
  }
`;

//Carousel

const BoxCarousel = styled.div`
  margin-top: 1.2rem;
  padding: 0 1rem;

  @media (min-width: ${props => props.theme.queries.lg}) {
    display: none;
  }
`;
