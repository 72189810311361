import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

//assets
import residencias from "assets/icons/house.png";
import empresas from "assets/icons/empresas.png";
import parceiros from "assets/icons/parceiros.png";
import rural from "assets/icons/rural.png";

const SectionInfo = () => {
  const { push } = useHistory();

  return (
    <Container>
      <Grid>
        <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true}>
          <Card>
            <img src={residencias} alt="residências" />
            <BoxCardtexts>
              <CardTitle>Projetos para residências</CardTitle>
              <CardSubtitle mTop=".5rem">
                Autonomia na sua geração
                <br /> de energia elétrica
              </CardSubtitle>
            </BoxCardtexts>
          </Card>
        </ScrollAnimation>

        <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true}>
          <Card>
            <img src={rural} alt="rurais" />
            <BoxCardtexts>
              <CardTitle lineHeight="1.2">
                Projetos para
                <br /> propriedades rurais
              </CardTitle>
              <CardSubtitle mTop=".5rem">
                Utilize o programa de
                <br /> financiamento para zona rural
              </CardSubtitle>
            </BoxCardtexts>
          </Card>
        </ScrollAnimation>

        <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true}>
          <Card>
            <img src={empresas} alt="empresas" />
            <BoxCardtexts>
              <CardTitle>Projetos para empresas</CardTitle>
              <CardSubtitle mTop=".5rem">
                Baixa necessidade de
                <br /> manutenção
              </CardSubtitle>
            </BoxCardtexts>
          </Card>
        </ScrollAnimation>

        <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true}>
          <Card>
            <img src={parceiros} alt="integradores e revendedores" />
            <BoxCardtexts>
              <CardTitle lineHeight="1.3">
                Integradores e<br /> Revendedores
              </CardTitle>
              <CardSubtitle mTop=".5rem">
                Condições especiais para parceiros
              </CardSubtitle>
            </BoxCardtexts>
          </Card>
        </ScrollAnimation>
      </Grid>

      <ScrollAnimation
        style={{ display: "flex", alignItems: "center" }}
        animateIn="animate__fadeInDown"
        animateOnce={true}
      >
        <SendButton onClick={() => push("/servicos")}>Saiba mais</SendButton>
      </ScrollAnimation>
    </Container>
  );
};

export default SectionInfo;

const Container = styled.section`
  max-width: ${props => props.theme.queries.lg};
  margin: 150px auto 100px auto;
  background-color: ${props => props.theme.color.white};

  @media (max-width: ${props => props.theme.queries.md}) {
    margin: 70px auto;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 75px 100px;
  margin-bottom: 2rem;

  @media (max-width: ${props => props.theme.queries.md}) {
    grid-gap: 40px 0px;
    grid-template-columns: repeat(1, 1fr);
    padding: 0 1rem;
  }
`;

const Card = styled.div`
  display: flex;
  align-items: center;

  img {
    min-width: 110px;
    width: 110px;
    min-height: 110px;
    height: 110px;
  }
`;

const BoxCardtexts = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  max-width: 375px;

  @media (max-width: ${props => props.theme.queries.md}) {
    margin-left: 10px;
  }
`;

const CardTitle = styled.div`
  color: ${props => props.theme.color.title};
  font-weight: 700;
  font-size: 1.3rem;
  line-height: ${props => props.lineHeight || "1.3"};

  @media (max-width: ${props => props.theme.queries.md}) {
    br {
      display: none;
    }
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    font-size: 1rem;
  }
`;

const CardSubtitle = styled.div`
  margin-top: ${props => props.mTop || "0"};
  color: ${props => props.theme.color.subtitle};
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.3;

  @media (max-width: ${props => props.theme.queries.md}) {
    br {
      display: none;
    }
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    font-size: 1rem;
  }
`;

const SendButton = styled.button`
  background-color: ${props => props.theme.color.orange};
  color: ${props => props.theme.color.white};
  border-radius: 2rem;
  margin: 0 auto;
  max-width: 270px;
  width: 100%;
  height: 45px;
  outline: none;
  margin-top: 60px;
  border: none;
  text-align: center;
  font-size: 1.2rem;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 500;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    width: 100%;
  }
`;
