import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

//assets
import howItWorks from "assets/images/how-it-works.png";

const SectionHowItWorks = () => {
  const { push } = useHistory();

  return (
    <Container>
      <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true}>
        <Title>Como funciona?</Title>
      </ScrollAnimation>
      <Grid>
        <List>
          <ListItem>
            <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
              <ListTitleItem>
                <span>01.</span> A GERAÇÃO DE ENERGIA COMEÇA COM LUMINOLIDADE
              </ListTitleItem>
              <ListDescriptionItem>
                Já nas primeiras horas do dia, o painel de luz começa a captação
                de energia solar aproveitando toda a claridade.
              </ListDescriptionItem>
            </ScrollAnimation>
          </ListItem>

          <ListItem>
            <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
              <ListTitleItem>
                <span>02.</span> O INVERSOR CONVERTE A ENERGIA GERADA
              </ListTitleItem>
              <ListDescriptionItem>
                A todo o momento, um inversor solar converte toda a energia
                gerada dos painéis fotovoltaicos em energia elétrica.
              </ListDescriptionItem>
            </ScrollAnimation>
          </ListItem>

          <ListItem>
            <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
              <ListTitleItem>
                <span>03.</span> A ENERGIA ELÉTRICA É DISTRIBUÍDA
              </ListTitleItem>
              <ListDescriptionItem>
                A energia elétrica gerada na luz do dia é consumida e seu
                excedente vai para a rede da concessionária.
              </ListDescriptionItem>
            </ScrollAnimation>
          </ListItem>

          <ListItem>
            <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
              <ListTitleItem>
                <span>04.</span> O EXCEDENTE DE ENERGIA GERA CRÉDITOS
              </ListTitleItem>
              <ListDescriptionItem>
                A geração de energia gera créditos para uso à noite e em
                períodos chuvosos.
              </ListDescriptionItem>
            </ScrollAnimation>
          </ListItem>
        </List>
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <img src={howItWorks} alt="como funciona ?" />
        </ScrollAnimation>
      </Grid>
      <SendButton
        onClick={() =>
          window.open(
            "https://loja.siriussolar.com.br/pagina/calculadora-solar",
            "_blank"
          )
        }
      >
        Calculadora Solar
      </SendButton>
      <SendButton mTop={"30px"} onClick={() => push("/contato")}>
        Solicitar orçamento
      </SendButton>
    </Container>
  );
};

export default SectionHowItWorks;

const Container = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 90px 0;
  margin: 0 auto;
  max-width: ${props => props.theme.queries.xl};

  @media (max-width: ${props => props.theme.queries.xl}) {
    padding: 90px 1rem;
  }
`;

const Title = styled.h2`
  color: ${props => props.theme.color.title};
  font-size: 3.125rem;
  font-weight: 900;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 1.2;

  @media (max-width: ${props => props.theme.queries.sm}) {
    font-size: 2.3rem;
  }

  @media (max-width: ${props => props.theme.queries.i5}) {
    font-size: 2rem;
  }
`;

const Grid = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: 520px 1fr;
  grid-gap: 20px;

  img {
    width: 100%;
  }

  @media (max-width: ${props => props.theme.queries.lg}) {
    grid-template-columns: 420px 1fr;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${props => props.theme.queries.i5}) {
    margin-top: 0px;
  }
`;

//List

const List = styled.ul`
  list-style: none;
  margin-top: 3rem;
  padding: 0;

  li:first-child {
    margin-top: 0 !important;
  }
`;

const ListItem = styled.li`
  margin-top: 35px;
  display: flex;
  flex-direction: column;
`;

const ListTitleItem = styled.h3`
  padding: 0;
  margin: 0;
  color: ${props => props.theme.color.title};
  font-size: 1.2rem;
  text-transform: uppercase;
  line-height: 1.6;
  span {
    color: ${props => props.theme.color.orange};
  }

  @media (max-width: ${props => props.theme.queries.i5}) {
    font-size: 1.1rem;
  }
`;

const ListDescriptionItem = styled.div`
  color: ${props => props.theme.color.subtitle};
  font-size: 1.2rem;
  line-height: 1.6;
  margin-top: 0.3rem;
`;

const SendButton = styled.button`
  background-color: ${props => props.theme.color.orange};
  color: ${props => props.theme.color.white};
  border-radius: 2rem;
  max-width: 520px;
  width: 100%;
  height: 45px;
  outline: none;
  margin-top: ${({ mTop }) => mTop || "60px"};
  border: none;
  text-align: center;
  font-size: 1.2rem;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 500;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    width: 100%;
  }
`;
