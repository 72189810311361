import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { scroller } from "react-scroll";
import queryString from "query-string";
import { Element } from "react-scroll";

//components
import SectionOne from "components/AboutUs/SectionOne";
import SectionTwo from "components/AboutUs/SectionTwo";
import SectionThree from "components/AboutUs/SectionThree";
import SectionSlider from "components/AboutUs/SectionSlider";
import SectionSteps from "components/AboutUs/SectionSteps";
import SectionBanks from "components/AboutUs/SectionBanks";

const AboutUs = () => {
  const location = useLocation();
  const { replace } = useHistory();
  const query = queryString.parse(location.search);
  const querySection = query.section || "";

  useEffect(() => {
    if (querySection.includes("bank")) {
      scroller.scrollTo("bank", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart"
      });
    }
    replace("/sobre-nos");
  }, [querySection]);

  return (
    <Container>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionSlider />
      <SectionSteps />
      <Element name={"bank"}>
        <SectionBanks />
      </Element>
    </Container>
  );
};

export default AboutUs;

const Container = styled.div``;
