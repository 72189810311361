import React from "react";
import styled from "styled-components";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Icon, ArrowRightIcon } from "evergreen-ui";

//components
import Banner from "./Banner";

//assets
import logoBlack from "assets/logo/sirius-solar-logo.png";

const Navbar = props => {
  const history = useHistory();
  const { pathname } = useLocation();

  const isShowBanner = () => {
    switch (pathname) {
      case "/contato":
        return false;
      case "/":
        return true;
      case "/sobre-nos":
        return true;
      case "/servicos":
        return false;
      case "/orcamento":
        return true;
    }
  };

  const selectTitleBanner = () => {
    switch (pathname) {
      case "/contato":
        return "";
      case "/":
        return `Eficiência Energética`;
      case "/sobre-nos":
        return "NÓS SOMOS A SIRIUS SOLAR";
      case "/servicos":
        return "";
      case "/orcamento":
        return "Simule e comprove!";
    }
  };

  return (
    <>
      <Content>
        <ContentBox>
          <BoxLogotype>
            <Logotype
              src={logoBlack}
              alt="Sirius Solar"
              onClick={() => history.push("/")}
            />
            <ContainerIcon>
              <Icon
                icon={props.icon}
                size={28}
                color={"#6A7380"}
                marginLeft={0}
                marginRight={0}
                onClick={props.displaySidebar}
              />
            </ContainerIcon>
          </BoxLogotype>
          <ContainerLinks>
            <Link to="/">Início</Link>
            <Link to="/sobre-nos">Sobre nós</Link>
            <Link to="/servicos?section=energy">Energia renovável</Link>
            <Link to="/servicos">Serviços</Link>
            <Link to="/sobre-nos?section=bank">Financiamentos</Link>
            <Link to="/contato">Contato</Link>
          </ContainerLinks>
        </ContentBox>
      </Content>
      {isShowBanner() ? <Banner title={selectTitleBanner()} /> : null}
    </>
  );
};

export default Navbar;

const Content = styled.div`
  height: 94px;
  background: ${props => props.theme.color.white};
`;

const ContentBox = styled.div`
  max-width: ${props => props.theme.queries.xl};
  padding: 0 1rem;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BoxLogotype = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.queries.md}) {
    width: 100%;
  }
`;

const Logotype = styled.img`
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  width: 173px;
  height: 63px;
  color: #374251;
  margin-left: -10px;

  @media (max-width: ${props => props.theme.queries.xl}) {
    width: 150px;
    height: 50px;
  }
`;

const ContainerIcon = styled.div`
  height: 23px;
  cursor: pointer;
  @media (max-width: ${props => props.theme.queries.md}) {
    display: block;
  }
  display: none;
`;

//links

const ContainerLinks = styled.nav`
  display: flex;
  align-items: center;

  a {
    margin-left: 2rem;
    color: ${props => props.theme.color.gray};
    text-decoration: none;
    font-size: 0.95rem;
    font-weight: 500;
    transition: 0.3s;

    :hover {
      color: ${props => props.theme.color.orange};
    }
  }

  @media (max-width: ${props => props.theme.queries.xl}) {
    a {
      font-size: 0.9rem;
    }
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    display: none;
  }
`;

const LinkMyShop = styled.a`
  background-color: ${props => props.theme.color.orange};
  border-radius: 0.2rem;
  color: ${props => props.theme.color.white} !important;
  font-weight: 600 !important;
  padding: 0 0.8rem;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;
