export const whitelistPages = () => {
  if (
    window.location.pathname.includes("login") ||
    window.location.pathname.includes("cadastro") ||
    window.location.pathname.includes("recover") ||
    window.location.pathname.includes("mudar-senha")
  ) {
    return true;
  }

  return false;
}