import { thunk, action, Action, Thunk } from "easy-peasy";
import { ExampleApi } from "../services/example";
import { get } from "lodash";

export interface ExampleModel {
  isLoading: boolean;
  error: string;

  data: Array<object>;

  getData: Thunk<ExampleModel, void>;
  setData: Action<ExampleModel, any>;
  setLoading: Action<ExampleModel, any>;
  setError: Action<ExampleModel, string>;
}

const example: ExampleModel = {
  isLoading: false,
  error: "",
  data: [],
  //GET LIST
  getData: thunk(async (action: any, payload: any) => {
    try {
      action.setLoading({ loading: true });
      const response = await ExampleApi();
      action.setData(response.data);
      action.setLoading({ loading: false });
    } catch (error) {
      action.setError({ message: "Error" });
      action.setLoading({ loading: false });
    }
  }),
  setData: action((state: any, payload: any) => {
    const res = get(payload, "results", []);
    state.data = res;
  }),
  //END

  setLoading: action((state: { isLoading: any }, payload: any) => {
    const loading = get(payload, "loading", false);
    state.isLoading = loading;
  }),
  setError: action((state: { error: any }, payload: any) => {
    const message = get(payload, "message", false);
    state.error = message;
  }),
};

export default example;
