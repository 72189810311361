import React from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useWindowSize from "hooks/useWindowSize";

//assets
import icon1 from "assets/icons/sessao3_icone1_quemsomos.png";
import icon2 from "assets/icons/sessao3_icone2_quemsomos.png";
import icon3 from "assets/icons/sessao3_icone3_quemsomos.png";

const SectionSlider = () => {
  const { width } = useWindowSize();

  const responsive = {
    big: {
      breakpoint: { max: 4000, min: 1680 },
      items: 3,
      partialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 1680, min: 1024 },
      items: 2,
      partialVisibilityGutter: 80
    },
    table: {
      breakpoint: { max: 1024, min: 440 },
      items: 1,
      partialVisibilityGutter: 90
    },
    mobile: {
      breakpoint: { max: 440, min: 320 },
      items: 1,
      partialVisibilityGutter: 30
    },
    i5: {
      breakpoint: { max: 320, min: 0 },
      items: 1,
      partialVisibilityGutter: 26
    }
  };

  return (
    <Container>
      <Center>
        <CustomCarousel responsive={responsive} arrows={false} partialVisible>
          <Card>
            <CardContent>
              <CardBoxTitle paddingBottom={"1rem"}>
                <img draggable={false} src={icon1} alt="Aqui tem uma imagem" />
                <h3>O que defendemos</h3>
              </CardBoxTitle>
              <CardBoxParagraphs>
                <p>A natureza vem sempre em primeiro lugar.</p>
                <p>O atendimento ao nosso cliente.</p>
                <p>Princípios éticos.</p>
                <p>Responsabilidade.</p>
                <p>Perenidade e Sustentabilidade.</p>
                <p>Responsabilidade.</p>
              </CardBoxParagraphs>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <CardBoxTitle>
                <img draggable={false} src={icon2} alt="Aqui tem uma imagem" />
                <h3>
                  COMO
                  <br /> FAREMOS ISSO?
                </h3>
              </CardBoxTitle>
              <CardBoxParagraphs>
                <p>
                  Através de estudos individualizados, caso a caso, para máxima
                  otimização das suas necessidades como cliente. A relação
                  custo/benefício é chave para um perfeito dimensionamento de
                  sistemas fotovoltaicos.
                </p>
              </CardBoxParagraphs>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <CardBoxTitle>
                <img draggable={false} src={icon3} alt="Aqui tem uma imagem" />
                <h3>PROPÓSITOS</h3>
              </CardBoxTitle>
              <CardBoxParagraphs>
                <p>
                  Nós nos propomos ser uma empresa no justo tamanho da
                  necessidade de nossos clientes, perene, eficiente e sempre
                  atual tecnologicamente e nas relações interpessoais.
                </p>
                <p>
                  Reconhecimento não vem com propaganda paga e folders bonitos,
                  e sim, com muito trabalho, respeito e dedicação pelos nossos
                  clientes.
                </p>
              </CardBoxParagraphs>
            </CardContent>
          </Card>
        </CustomCarousel>
      </Center>
    </Container>
  );
};

export default SectionSlider;

const Container = styled.section`
  padding: 150px 0;
  background-color: ${props => props.theme.color.orange};
`;

const Center = styled.div`
  max-width: ${props => props.theme.queries.giant};
  margin: 0 auto;
`;

const CustomCarousel = styled(Carousel)`
  li[data-index="0"] {
    margin-left: 4rem;
  }

  li {
    max-width: 725px !important;
  }

  @media (max-width: ${props => props.theme.queries.xl}) {
    li[data-index="0"] {
      margin-left: 1rem;
    }
  }
`;

//CARD

const Card = styled.div`
  width: 90%;
  height: 100%;
  background-color: ${props => props.theme.color.white};
  cursor: grab;
`;

const CardContent = styled.div`
  min-height: 73%;
  padding: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${props => props.theme.queries.sm}) {
    padding: 30px;
  }
`;

const CardBoxTitle = styled.div`
  display: flex;

  img {
    max-width: 90px;
    max-height: 90px;
    width: 100%;
    height: 90px;
    margin-right: 24px;
  }

  h3 {
    margin: 0;
    color: ${props => props.theme.color.orange};
    font-size: 1.6rem;
    align-self: flex-end;
    line-height: 1.3;
    padding-bottom: ${props => props.paddingBottom || "0.1rem"};
    text-transform: uppercase;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    flex-direction: column;

    h3 {
      margin-top: 1rem;
      font-size: 1.4rem;
      align-self: flex-start;
    }
  }
`;

const CardBoxParagraphs = styled.div`
  margin-top: 1.3rem;
  p {
    margin: 0;
    color: ${props => props.theme.color.title};
    font-size: 1.1rem;
    line-height: 1.8;
    text-align: justify;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    margin-top: 0.5rem;
    p {
      font-size: 1rem;
    }
  }

  @media (max-width: ${props => props.theme.queries.i5}) {
    p {
      font-size: 1rem;
    }
  }
`;
