import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

//assets
import icone1 from "assets/icons/sessao2_icone1_home.png";
import icone2 from "assets/icons/sessao2_icone2_home.png";
import icone3 from "assets/icons/sessao2_icone3_home.png";
import icone4 from "assets/icons/sessao2_icone4_home.png";
import icone5 from "assets/icons/sessao2_icone5_home.png";

const SectionWhyChoose = () => {
  return (
    <Container>
      <Content>
        <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true}>
          <Title>
            Por que escolher a<br /> energia solar fotovoltaica?
          </Title>
        </ScrollAnimation>
        <Grid>
          <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
            <Card>
              <img src={icone1} alt="Economia" />
              <div>Economia com custos de energia</div>
            </Card>
          </ScrollAnimation>

          <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
            <Card>
              <img src={icone2} alt="Ambiente" />
              <div>Não agride o meio ambiente</div>
            </Card>
          </ScrollAnimation>

          <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
            <Card>
              <img src={icone3} alt="baixo custo" />
              <div>Baixo custo em relação á vida útil</div>
            </Card>
          </ScrollAnimation>

          <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
            <Card>
              <img src={icone4} alt="Renovavel" />
              <div>Renovável e infinita</div>
            </Card>
          </ScrollAnimation>

          <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
            <Card>
              <img src={icone5} alt="Minimo de manutenção" />
              <div>Necessita do mínimo de manutenção</div>
            </Card>
          </ScrollAnimation>
        </Grid>
      </Content>
    </Container>
  );
};

export default SectionWhyChoose;

const Container = styled.section`
  background-color: ${props => props.theme.color.orange};
  padding: 100px 0;
`;

const Content = styled.div`
  max-width: ${props => props.theme.queries.xl};
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${props => props.theme.queries.md}) {
    padding: 0 1rem;
  }
`;

const Title = styled.h2`
  color: ${props => props.theme.color.white};
  font-size: 3.125rem;
  font-weight: 800;
  margin: 0;
  padding: 0;
  max-width: 860px;
  text-align: center;
  line-height: 1.2;

  @media (max-width: ${props => props.theme.queries.sm}) {
    font-size: 2rem;
  }
`;

const Grid = styled.div`
  margin-top: 115px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0 50px;

  @media (max-width: ${props => props.theme.queries.md}) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px 30px;
    margin-top: 70px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 130px;
    height: 130px;
    background-color: #eee;
    border-radius: 100%;
  }

  img {
    /* width: 130px;
    height: 130px;
    background-color: #eee;
    border-radius: 100%; */
  }

  div {
    margin-top: 22px;
    color: ${props => props.theme.color.white};
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: 600;
    text-align: center;
  }
`;
