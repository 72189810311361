import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { Layout } from "../components/Layout";

import Home from "pages/home";
import Contact from "pages/contact";
import AboutUs from "pages/aboutUs";
import Services from "pages/services";
// import Budgets from "pages/budgets";
// import BudgetsDetail from "pages/budgetsDetail";

const Routes = ({ history }) => (
  <Router history={history}>
    <Layout>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/contato" component={Contact} />
        <Route exact path="/sobre-nos" component={AboutUs} />
        <Route exact path="/servicos" component={Services} />
        {/* <Route exact path="/orcamento" component={Budgets} />
        <Route exact path="/orcamento/resultado" component={BudgetsDetail} /> */}
      </Switch>
    </Layout>
  </Router>
);

export default Routes;
