import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import styled from "styled-components";
import { SideSheet, Position, Icon, CrossIcon } from "evergreen-ui";

import logoBlack from "assets/logo/sirius-solar-logo.png";

const Sidebar = (props: any) => {
  const { push } = useHistory();

  const handleRoutes = (route: string, type?: string) => {
    if (type === "external") {
      window.open(route, "_blank");
      props.closeSidebar();
    } else {
      push(route);
      props.closeSidebar();
    }
  };

  return (
    <SideSheet
      width="100%"
      position={Position.TOP as any}
      isShown={props.show}
      onCloseComplete={props.closeSidebar}
    >
      <Container>
        <Content>
          <BoxLogotype>
            <Logotype
              src={logoBlack}
              alt="Sirius Solar"
              onClick={() => push("/")}
            />
            <Icon
              icon={CrossIcon}
              size={28}
              color={"#6A7380"}
              marginLeft={0}
              marginRight={0}
              cursor="pointer"
              onClick={props.closeSidebar}
            />
          </BoxLogotype>
          <NavBox>
            <ul>
              <li onClick={() => handleRoutes("/")}>Início</li>
              <li onClick={() => handleRoutes("/sobre-nos")}>Sobre nós</li>
              <li onClick={() => handleRoutes("/servicos?section=energy")}>
                Energia renovável
              </li>
              <li onClick={() => handleRoutes("/servicos")}>Serviços</li>
              <li onClick={() => handleRoutes("/sobre-nos?section=bank")}>
                Financiamentos
              </li>
              <li onClick={() => handleRoutes("/contato")}>Contato</li>
              <li
                onClick={() =>
                  handleRoutes(
                    "https://oferta.siriussolar.com.br/principal",
                    "external"
                  )
                }
              >
                Nossa Loja
              </li>
            </ul>
          </NavBox>
        </Content>
      </Container>
    </SideSheet>
  );
};

export default withRouter(Sidebar);

const Container = styled.div`
  overflow-y: auto;
  height: 100vh;
`;

const Content = styled.div`
  display: block;
  padding: 1rem;
`;

const NavBox = styled.nav`
  margin-top: 3rem;

  ul {
    list-style: none;
    padding: 0 1rem;
    margin: 6rem 0 0 0;
  }

  li {
    font-size: 1.875rem;
    font-weight: 600;
    color: ${props => props.theme.color.subtitle};
    margin-top: 0.7rem;
    cursor: pointer;
    text-align: center;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    li {
      font-size: 1.4rem;
    }
  }
`;

//HEADER

const BoxLogotype = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.queries.md}) {
    width: 100%;
  }
`;

const Logotype = styled.img`
  cursor: pointer;
  width: 173px;
  height: 63px;
  color: #374251;
  margin-left: -15px;
`;
